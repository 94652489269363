import { get } from 'lodash';
import { SCHEMA_URL, SITE_URL } from 'site-modules/shared/pages/seo';
import { getMakeModelYearLinkUrl } from 'site-modules/shared/utils/vehicle-link-constructor';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';

export function getPhoneNumber(phoneNumberObj) {
  const { areaCode, prefix, postfix } = phoneNumberObj;
  return areaCode && prefix && postfix ? `${areaCode}-${prefix}-${postfix}` : '';
}
export function getOfferVinSchema({ vehicle, urlCity = '', urlStateName = '' }) {
  const { make, model, year } = get(vehicle, 'vehicleInfo.styleInfo', {});
  const { city, stateName, stateCode, zip, street } = get(vehicle, 'dealerInfo.address', {});
  const addSellertoJsonLd = urlCity && urlCity === city && urlStateName && urlStateName === stateName;
  const makeSlug = makeNiceName(make);
  const modelSlug = makeNiceName(model);
  const vin = get(vehicle, 'vin');
  const cityStateNameDescPart = city && stateName ? ` in ${city}, ${stateName}` : '';

  const schema = {
    '@context': SCHEMA_URL,
    '@type': 'Offer',
    availability: `${SCHEMA_URL}/InStock`,
    price: get(vehicle, 'prices.displayPrice', 0),
    priceCurrency: 'USD',
    url: `${SITE_URL}${getMakeModelYearLinkUrl({ makeSlug, modelSlug, year }, `vin/${vin}`)}/`,
    description: `This ${year} ${make} ${model} is in stock and for sale${cityStateNameDescPart}. View photos and learn more about this ${year} ${model} on Edmunds.`,
  };
  if (addSellertoJsonLd) {
    const dealerName = get(vehicle, 'dealerInfo.name');
    const trackablePhoneNumber = getPhoneNumber(get(vehicle, 'dealerInfo.phoneNumbers.trackable', {}));
    const basicPhoneNumber = getPhoneNumber(get(vehicle, 'dealerInfo.phoneNumbers.basic', {}));
    schema.seller = {
      '@context': SCHEMA_URL,
      '@type': 'AutoDealer',
      name: dealerName,
      telephone: trackablePhoneNumber || basicPhoneNumber,
      address: {
        '@context': SCHEMA_URL,
        '@type': 'PostalAddress',
        addressLocality: city,
        addressRegion: stateCode,
        postalCode: zip,
        streetAddress: street,
      },
    };
  }
  return schema;
}

export function getAggregateOfferSchema({ searchResults }) {
  const inventories = get(searchResults, 'inventories.results', []);

  const highPrice = inventories.reduce((highestPrice, vin) => {
    const vinPrice = get(vin, 'prices.displayPrice', 0);
    return vinPrice && (!highestPrice || vinPrice > highestPrice) ? vinPrice : highestPrice;
  }, undefined);
  const lowPrice = inventories.reduce((lowestPrice, vin) => {
    const vinPrice = get(vin, 'prices.displayPrice', 0);
    return vinPrice && (!lowestPrice || vinPrice < lowestPrice) ? vinPrice : lowestPrice;
  }, undefined);

  return lowPrice
    ? {
        '@context': SCHEMA_URL,
        '@type': 'AggregateOffer',
        lowPrice,
        highPrice,
        offerCount: get(searchResults, 'inventories.totalNumber'),
        priceCurrency: 'USD',
      }
    : null;
}
