import { SCHEMA_URL } from 'site-modules/shared/pages/seo';
import { getOrganizationSchema } from './organization';

export function getPersonSchema({ name, jobTitle, pathname, photoUrl }) {
  return {
    '@context': SCHEMA_URL,
    '@type': 'Person',
    name,
    jobTitle,
    image: photoUrl,
    url: `https://www.edmunds.com${pathname}`,
    worksFor: getOrganizationSchema(),
  };
}

export function getPersonSimpleSchema(name) {
  if (!name) {
    return undefined;
  }

  return {
    '@context': SCHEMA_URL,
    '@type': 'Person',
    name,
  };
}
