export const WARRANTY_MAX_TYPE = {
  LIMITED: 'LIMITED',
  UNLIMITED: 'UNLIMITED',
};

export const WARRANTY_TYPE = {
  EV_BATTERY: 'EV Battery',
  HYBRID_COMPONENT: 'Hybrid Component',
  BASIC: 'Basic',
  DRIVETRAIN: 'Drivetrain',
  RUST: 'Rust',
  ROADSIDE: 'Roadside',
  FREE_COMPLIMENTARY_MAINTENANCE: 'Free Maintenance',
};

export const MAX_COLLAPSED_WARRANTY_COUNT = 3;
export const MAX_DETAILED_WARRANTY_USED_LIST_COUNT = 2;
