import { objectToQueryString } from 'site-modules/shared/utils/string';

export function getRatingScale(editorialReview) {
  return editorialReview && editorialReview.isTenPointScale ? 10 : 5;
}

export function getSellCarPageUrl(params = {}) {
  const queryParams = objectToQueryString(params);

  return `/sell-car/${queryParams.length ? '?' : ''}${queryParams}`;
}
