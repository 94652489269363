import { SCHEMA_URL, DEFAULT_IMAGE_URL, DEFAULT_IMAGE_SIZE } from 'site-modules/shared/pages/seo';

export function getOrganizationSchema({ isTextLogo = false } = {}) {
  return {
    '@context': SCHEMA_URL,
    '@type': 'Organization',
    name: 'Edmunds',
    logo: {
      '@context': SCHEMA_URL,
      '@type': 'ImageObject',
      ...(isTextLogo
        ? {
            url: 'https://static.ed.edmunds-media.com/unversioned/edmunds-logo-and-text-generic.png',
            width: 226,
            height: 36,
          }
        : {
            url: DEFAULT_IMAGE_URL,
            width: DEFAULT_IMAGE_SIZE.WIDTH,
            height: DEFAULT_IMAGE_SIZE.HEIGHT,
          }),
    },
  };
}
