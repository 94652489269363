import capitalize from 'lodash/capitalize';
import { get, orderBy, groupBy, toPairs, flatten } from 'lodash';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { isDiscontinuedDate } from 'site-modules/shared/utils/vehicle-utils';
import { numberWithCommasForString } from 'site-modules/shared/utils/string';
import { getBedLengthValue } from 'site-modules/shared/utils/features-format';
import { ELECTRIC_FUEL_TYPE } from 'site-modules/shared/constants/features-specs';

export const NOT_AVAILABLE = 'N/A';
const EMPTY_RANGE_VALUE = '0/0 mi.';
const mpgRegex = /(\d+)/g;
const styleNameRegex = /(.+)\s+\((.+)\)/;

export function getStyleName(nameString = '') {
  const nameMatches = nameString.match(styleNameRegex) || [];
  return {
    style: nameMatches[1] || nameString || NOT_AVAILABLE,
    engine: nameMatches[2] || NOT_AVAILABLE,
  };
}

export function getBaseMSRP(price) {
  const baseMSRP = get(price, 'baseMSRP');
  return baseMSRP ? formatPriceString(parseInt(baseMSRP, 10)) : NOT_AVAILABLE;
}

export function transformRangeValue(value) {
  return !value || value === EMPTY_RANGE_VALUE ? NOT_AVAILABLE : value;
}

const noTransform = value => value;
export function getFeatureValue(features, path, featureName, fallbackValue, transform = noTransform) {
  const feature = get(features, path, []);
  const featureValue = get(feature.find(({ name }) => name === featureName), 'value');

  return featureValue ? transform(featureValue) : fallbackValue;
}

export function getMpg(features) {
  const mpgMatchesOld =
    getFeatureValue(features, 'standard.Fuel', 'EPA mileage est. (cty/hwy)', '').match(mpgRegex) || [];
  const combinedMpgMatchesOld = getFeatureValue(features, 'standard.Fuel', 'Combined MPG', '').match(mpgRegex) || [];

  const mpgMatches = getFeatureValue(features, 'standard.Fuel', 'EPA city/highway MPG', '').match(mpgRegex) || [];
  const combinedMatches = getFeatureValue(features, 'standard.Fuel', 'EPA combined MPG', '').match(mpgRegex) || [];

  return {
    city: mpgMatches[0] || mpgMatchesOld[0] || NOT_AVAILABLE,
    hwy: mpgMatches[1] || mpgMatchesOld[1] || NOT_AVAILABLE,
    combined: combinedMatches[0] || combinedMpgMatchesOld[0] || NOT_AVAILABLE,
  };
}

export function getMpge(features) {
  const mpgeMatches = getFeatureValue(features, 'standard.Fuel', 'EPA city/highway MPGe', '').match(mpgRegex) || [];
  const combinedMatches =
    getFeatureValue(
      features,
      'standard.Fuel',
      'EPA Combined MPGe',
      getFeatureValue(features, 'standard.Fuel', 'EPA combined MPGe', '')
    ).match(mpgRegex) || [];

  return {
    city: mpgeMatches[0] || NOT_AVAILABLE,
    hwy: mpgeMatches[1] || NOT_AVAILABLE,
    combined: combinedMatches[0] || NOT_AVAILABLE,
  };
}

export function formatStyleDetails(style) {
  const { name, price, features, styleAttributes } = style || {};
  const fuelType = getFeatureValue(features, 'standard.Fuel', 'Fuel type', NOT_AVAILABLE);
  const bedLength = getFeatureValue(
    features,
    'standard.Measurements',
    'Bed Length',
    getFeatureValue(features, 'standard.Measurements', 'Bed length', NOT_AVAILABLE)
  );
  const electricRange = getFeatureValue(
    features,
    'standard.Fuel',
    'EPA Electricity Range',
    getFeatureValue(features, 'standard.Fuel', 'EPA electricity range', NOT_AVAILABLE)
  );

  const isElectric = fuelType.toLowerCase() === ELECTRIC_FUEL_TYPE;

  return {
    styleName: getStyleName(name),
    baseMSRP: getBaseMSRP(price),
    mpg: getMpg(features),
    fuelCapacity: getFeatureValue(features, 'standard.Fuel', 'Fuel tank capacity', NOT_AVAILABLE),
    totalSeating: get(style, 'totalSeating', NOT_AVAILABLE),
    transmission: getFeatureValue(features, 'standard["Drive Train"]', 'Transmission', NOT_AVAILABLE, capitalize),
    engineType: getFeatureValue(features, 'standard.Engine', 'Base engine type', NOT_AVAILABLE, capitalize),
    cylinders: getFeatureValue(features, 'standard.Engine', 'Cylinders', NOT_AVAILABLE, capitalize),
    engineSize: getFeatureValue(features, 'standard.Engine', 'Base engine size', NOT_AVAILABLE),
    driveType: getFeatureValue(features, 'standard["Drive Train"]', 'Drive type', NOT_AVAILABLE),
    horsepower: getFeatureValue(features, 'standard.Engine', 'Horsepower', NOT_AVAILABLE),
    torque: getFeatureValue(features, 'standard.Engine', 'Torque', NOT_AVAILABLE),
    fuelType,
    basicWarranty: getFeatureValue(features, 'standard.Warranty', 'Basic', NOT_AVAILABLE),
    range: getFeatureValue(
      features,
      'standard.Fuel',
      'Range in miles (cty/hwy)',
      getFeatureValue(features, 'standard.Fuel', 'Range in miles (city/hwy)', NOT_AVAILABLE, transformRangeValue),
      transformRangeValue
    ),
    measurements: {
      height: getFeatureValue(features, 'standard.Measurements', 'Height', NOT_AVAILABLE),
      width: getFeatureValue(features, 'standard.Measurements', 'Width', NOT_AVAILABLE),
      widthWithMirrors: getFeatureValue(
        features,
        'standard.Measurements',
        'Overall Width with Mirrors',
        getFeatureValue(features, 'standard.Measurements', 'Overall width with mirrors', NOT_AVAILABLE)
      ),
      widthWithoutMirrors: getFeatureValue(
        features,
        'standard.Measurements',
        'Overall Width without Mirrors',
        getFeatureValue(features, 'standard.Measurements', 'Overall width without mirrors', NOT_AVAILABLE)
      ),
      length: getFeatureValue(features, 'standard.Measurements', 'Length', NOT_AVAILABLE),
      curbWeight: getFeatureValue(features, 'standard.Measurements', 'Curb weight', NOT_AVAILABLE),
      cargoAllSeats: getFeatureValue(
        features,
        'standard.Measurements',
        'Cargo capacity, all seats in place',
        NOT_AVAILABLE
      ),
      passengerVolume: getFeatureValue(features, 'standard.Measurements', 'EPA interior volume', NOT_AVAILABLE),
      wheelBase: getFeatureValue(
        features,
        'standard.Measurements',
        'Wheel base',
        getFeatureValue(features, 'standard.Measurements', 'Wheelbase', NOT_AVAILABLE)
      ),
    },
    electric: {
      isElectric: styleAttributes ? !!styleAttributes.electric : isElectric,
      isPluginElectric: styleAttributes
        ? !!styleAttributes.pluginElectric
        : !isElectric && electricRange !== NOT_AVAILABLE,
      eRange: electricRange,
      mpge: getMpge(features),
      kWhPer100: getFeatureValue(features, 'standard.Fuel', 'EPA kWh/100 mi', NOT_AVAILABLE),
      timeToCharge: getFeatureValue(
        features,
        'standard.Fuel',
        'EPA Time to charge battery (at 240V)',
        getFeatureValue(features, 'standard.Fuel', 'EPA time to charge battery (at 240V)', NOT_AVAILABLE)
      ),
      batteryWarranty: getFeatureValue(features, 'standard.Warranty', 'EV battery', NOT_AVAILABLE),
    },
    truck: {
      isTruck: styleAttributes ? !!styleAttributes.truck : !!bedLength && bedLength !== NOT_AVAILABLE,
      bedLength: getBedLengthValue(bedLength),
      maxPayload: getFeatureValue(
        features,
        'standard.Measurements',
        'Maximum payload',
        NOT_AVAILABLE,
        numberWithCommasForString
      ),
      maxTowingCapacity: getFeatureValue(
        features,
        'standard.Measurements',
        'Maximum towing capacity',
        NOT_AVAILABLE,
        numberWithCommasForString
      ),
    },
  };
}

export function getDropdownItems(stylesList) {
  const mostPopularStyleId = get(stylesList, '[0].id');

  return flatten(
    orderBy(toPairs(groupBy(orderBy(stylesList, 'price.baseMSRP'), 'trim')), '[1][0].price.baseMSRP').map(
      pair => pair[1]
    )
  ).map(({ id, name, price, styleEndDate }) => {
    const baseMsrp = getBaseMSRP(price);
    const isDiscontinued = isDiscontinuedDate(styleEndDate);
    const isMostPopular = !isDiscontinued && id === mostPopularStyleId;

    let labelText;
    switch (true) {
      case isDiscontinued: {
        labelText = 'Discontinued';
        break;
      }
      case isMostPopular: {
        labelText = 'Most Popular';
        break;
      }
      default: {
        break;
      }
    }

    return {
      styleId: id,
      name: `${name}${baseMsrp === NOT_AVAILABLE ? '' : ` - ${baseMsrp}`}${labelText ? ` (${labelText})` : ''}`,
    };
  });
}

export function isAvailable(value) {
  return !!value && value !== NOT_AVAILABLE;
}
