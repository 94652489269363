import { get, times } from 'lodash';
import { getEdmundsImageUrl } from 'client/utils/image-helpers';
import { getVinImage } from 'client/utils/inventory-image-helper';

const buildVinImages = (largePhotoCount, vin) => times(largePhotoCount, index => getVinImage(vin, index + 1, 600));

/**
 * Returns default image for an inventory.
 * Checks whether dealer photos are available first.
 * If not then checks stock photos.
 * Returns null if neither dealer nor stock photos are there.
 */
export function getSeoImage(vinData, stockPhotos, imageDataPath) {
  const { vin } = vinData;
  const largePhotoCount = get(vinData, imageDataPath, 0);
  const image = (largePhotoCount && buildVinImages(1, vin)[0]) || (stockPhotos && stockPhotos.length && stockPhotos[0]);
  return image
    ? {
        url: getEdmundsImageUrl(image),
        width: 600,
        height: 400,
      }
    : null;
}
