import { forEach } from 'lodash';
import { formatStyleDetails } from 'site-modules/shared/utils/features-specs/utils';
import { ROBOTS_MAP, DEFAULT_TITLE, DEFAULT_DESCRIPTION } from 'site-modules/shared/constants/seo';

/**
 * Get SEO image from content if image url exist.
 * @param {Object} content
 */
export const getSeoImage = content => {
  const imageContent = content.child('seo-image');
  const url = imageContent.metadata('image-url').value();

  return url
    ? {
        url,
        width: imageContent.metadata('image-width').value(),
        height: imageContent.metadata('image-height').value(),
        alt: imageContent.metadata('image-alt').value(),
      }
    : null;
};

export const getCanonical = content =>
  content
    .metadata('canonical')
    .value()
    .replace(/^http:/, 'https:');

/**
 * Get simple editorial-focused meta for content
 * @param {Object} content
 */
export const getMeta = content => ({
  title: content.metadata('titleTag').value(content && content.title ? `${content.title} | Edmunds` : DEFAULT_TITLE),
  description: content.metadata('metaDescription').value(DEFAULT_DESCRIPTION),
  keywords: content.metadata('metaKeywords').value(),
  canonical: getCanonical(content),
  socialDescription: content.metadata('socialDescription').value(),
});

/**
 * Get meta robots based on ROBOTS_MAP.
 * @param {Object} content
 */
export const getMetaRobots = content => {
  const robots = content
    .metadata('metaRobots')
    .value()
    .replace(/\s+/g, '')
    .split(',');
  const robotsOptions = {};
  forEach(ROBOTS_MAP, (value, key) => {
    if (robots.includes(value)) {
      robotsOptions[key] = value;
    }
  });

  return {
    robots: { ...robotsOptions },
  };
};

/**
 * Get data for SEO Schemas from features
 * @param {Object} features
 */
export const getSeoDataFromFeatures = features => {
  const {
    fuelType,
    transmission,
    engineSize,
    engineType,
    horsepower,
    measurements: { width, height, curbWeight },
    mpg: { combined, city, hwy },
    electric: {
      mpge: { combined: eCombined, city: eCity, hwy: eHwy },
    },
  } = formatStyleDetails({ features });

  return {
    generalData: {
      fuelType,
      vehicleTransmission: transmission,
    },
    measurementsData: {
      width: width.split(' ')[0],
      height: height.split(' ')[0],
      weight: curbWeight.split(' ')[0],
    },
    mpg: {
      engineType: engineType && engineType.toLowerCase(),
      mpgData: {
        combined,
        city,
        highway: hwy,
      },
      mpgeData: {
        combined: eCombined,
        city: eCity,
        highway: eHwy,
      },
    },
    engineData: {
      engineVolume: engineSize.split(' ')[0],
      horsepower: horsepower.split(' ')[0],
      engineType,
    },
  };
};
