import { get, without, isNil, capitalize, head } from 'lodash';
import { SCHEMA_URL } from 'site-modules/shared/pages/seo';
import { MPGE_ENGINES } from 'site-modules/shared/constants/mpg';
import { getSeoDataFromFeatures } from 'site-modules/shared/utils/seo-helper';
import { transformVehicleHistory } from 'site-modules/shared/utils/inventory/srp-card-utils';
import { ALLOWED_HISTORY_PROVIDERS } from 'site-modules/shared/constants/inventory/vehicle-history';
import { showTrimName } from 'client/site-modules/shared/utils/show-trim-name';
import { getDamPhoto } from 'client/utils/image-service';
import { isPreProd } from 'site-modules/shared/utils/publication-states';
import { getUsurpImages } from 'client/site-modules/shared/utils/inventory/srp-images';

import { getOfferVinSchema } from './offer';
import { getAggregateRatingFromCoreConsumerReviews } from './review';

/**
 * Get vehicle interior color
 * @param vinData
 * @return {Object} interior color
 */
export function getVehicleInteriorColor(vinData) {
  const vehicleInteriorColor = get(vinData, 'vehicleInfo.vehicleColors.interior.name', '');
  return vehicleInteriorColor ? { vehicleInteriorColor } : null;
}

/**
 * Build description without empty values
 * @param vinData
 * @return {String} Description
 */
export function getCarSchemaDescription(vinData) {
  const { cylinders, doors } = get(vinData, 'vehicleInfo.partsInfo', {});
  const description = [
    get(vinData, 'vehicleInfo.partsInfo.transmission', null),
    get(vinData, 'vehicleInfo.partsInfo.driveTrain', null),
    !isNil(cylinders) ? `${cylinders} cylinders` : null,
    get(vinData, 'vehicleInfo.styleInfo.bodyType', null),
    !isNil(doors) ? `${doors} doors` : null,
  ];
  return without(description, null).join(', ');
}

/**
 * Get vehicle mpg fuel efficiency schema
 *
 * @param mpgStyle
 * @return {Object}
 */
export function getFuelEfficiencyCarSchema(mpgStyle) {
  if (!mpgStyle) {
    return {};
  }

  const isMpge = MPGE_ENGINES.includes(get(mpgStyle, 'engineType', ''));
  const mpgData = isMpge ? mpgStyle.mpgeData : mpgStyle.mpgData;

  return {
    fuelEfficiency: {
      '@context': SCHEMA_URL,
      '@type': 'QuantitativeValue',
      unitText: isMpge ? 'mpge' : 'mpg',
      minValue: get(mpgData, 'highway'),
      maxValue: get(mpgData, 'city'),
      value: get(mpgData, 'combined'),
    },
  };
}

/**
 * Get vehicle EngineSpecification schema
 *
 * @param {Object} engineData
 * @return {Object}
 */
export function getVehicleEngineSchema(engineData) {
  if (!engineData) return {};

  const { engineVolume, engineType, horsepower } = engineData;

  return {
    vehicleEngine: {
      '@context': SCHEMA_URL,
      '@type': 'EngineSpecification',
      engineType,
      enginePower: {
        '@context': SCHEMA_URL,
        '@type': 'QuantitativeValue',
        unitText: 'hp',
        value: horsepower,
      },
      engineDisplacement: {
        '@context': SCHEMA_URL,
        '@type': 'QuantitativeValue',
        unitText: 'LTR',
        value: engineVolume,
      },
    },
  };
}

/**
 * Testing tool: https://search.google.com/structured-data/testing-tool
 * Schema: http://schema.org/Car
 *
 * @param {Object} vinData Inventory data
 * @param {Boolean} disableInventoryMLPhotoSort
 * @return {Object} seo vehicle description
 */
export function getCarVinSchema({ vinData, disableInventoryMLPhotoSort, city = '', stateName = '' }) {
  const { stockNumber, vin } = vinData;
  const { make, model, year, style, trim = '' } = get(vinData, 'vehicleInfo.styleInfo', {});
  const { transmission = '', driveTrain = '' } = get(vinData, 'vehicleInfo.partsInfo', {});
  const mileage = get(vinData, 'vehicleInfo.mileage', '');
  const itemCondition = vinData.type.toLowerCase();

  const historyInfo = get(vinData, 'historyInfo', {});
  const { ownerText, usageType, historyProvider } = historyInfo;
  const { accidentText } = transformVehicleHistory(historyInfo);
  const vehicleHistory = !ALLOWED_HISTORY_PROVIDERS.includes(historyProvider)
    ? {}
    : {
        knownVehicleDamages: accidentText,
        numberOfPreviousOwners: ownerText,
        vehicleSpecialUsage: usageType,
      };

  const { photoUrls } = getUsurpImages(vinData, disableInventoryMLPhotoSort);
  const image = head(photoUrls);

  const schema = {
    '@context': SCHEMA_URL,
    '@type': 'Vehicle',
    brand: {
      '@type': 'Brand',
      name: make,
    },
    model,
    itemCondition,
    manufacturer: make,
    productionDate: `${year}`,
    name: `${year} ${make} ${model} ${showTrimName(trim) ? trim : ''}`,
    description: getCarSchemaDescription(vinData),
    image,
    sku: stockNumber,
    driveWheelConfiguration: driveTrain,
    vehicleConfiguration: style,
    vehicleTransmission: transmission,
    vehicleIdentificationNumber: vin,
    color: `${get(vinData, 'vehicleInfo.vehicleColors.exterior.name', '')}`,
    mileageFromOdometer: mileage ? `${mileage} SMI` : '',
    ...getVehicleInteriorColor(vinData),
    ...vehicleHistory,
    offers: getOfferVinSchema({ vehicle: vinData, urlCity: city, urlStateName: stateName }),
  };
  return schema;
}

/**
 * Testing tool: https://search.google.com/structured-data/testing-tool
 * Schema: http://schema.org/Car
 */
export function getEditorialReviewCarSchema({
  makeModelSubmodelYear,
  editorialReview,
  seoObject,
  priceRange = [0],
  url,
  thumbUrl,
  imageUrl,
  isCorePage = false,
}) {
  const make = get(makeModelSubmodelYear, 'make.name', '');
  const model = get(makeModelSubmodelYear, 'model.name', '');
  const year = get(makeModelSubmodelYear, 'year', '');
  const isPreprod = isPreProd(get(makeModelSubmodelYear, 'pubStates', ''));

  const minPrice = Math.min(...priceRange);
  const maxPrice = Math.max(...priceRange);
  const heroImageLink = get(editorialReview, 'heroImage.href');

  return {
    '@context': SCHEMA_URL,
    '@type': ['Vehicle', 'Product'],
    brand: make,
    model,
    manufacturer: make,
    productionDate: year,
    name: `${year} ${make} ${model} Review`,
    description: get(seoObject, 'description', ''),
    ...(isCorePage ? { url } : {}),
    ...((minPrice || maxPrice) &&
      !isPreprod && {
        offers: {
          '@type': 'Offer',
          price: minPrice || maxPrice,
          priceCurrency: 'USD',
          priceSpecification: {
            '@type': 'priceSpecification',
            minPrice,
            maxPrice,
            price: minPrice || maxPrice,
            priceCurrency: 'USD',
          },
        },
      }),
    image: !isCorePage
      ? `${heroImageLink ? getDamPhoto(heroImageLink) : ''}`
      : {
          '@type': 'ImageObject',
          url: imageUrl,
          height: 1067,
          width: 1600,
          thumbnail: {
            '@type': 'ImageObject',
            name: thumbUrl,
          },
        },
  };
}

/**
 * Testing tool: https://search.google.com/structured-data/testing-tool
 * Schema: http://schema.org/Car
 *
 * For use with Mpg Page
 *
 * @param {Object} makeModelSubmodelYear Vehicle object
 * @param {Object} mpgStyle object with MPG data for style
 * @param {Object} seoObject SEO data object
 */
export function getMpgPageCarSchema({ makeModelSubmodelYear, mpgStyle, seoObject }) {
  return {
    ...getEditorialReviewCarSchema({ makeModelSubmodelYear, seoObject }),
    ...getFuelEfficiencyCarSchema(mpgStyle),
  };
}

/**
 * Testing tool: https://search.google.com/structured-data/testing-tool
 * Schema: http://schema.org/Car
 *
 * @return {Object} seo vehicle description
 */
export function getCorePageCarSchema({
  makeModelSubmodelYear,
  editorialReview,
  consumerReviews,
  seoObject,
  priceRange = [0],
  url,
  thumbUrl,
  imageUrl,
  isCorePage = false,
}) {
  return {
    ...getEditorialReviewCarSchema({
      makeModelSubmodelYear,
      editorialReview,
      seoObject,
      priceRange,
      url,
      thumbUrl,
      imageUrl,
      isCorePage,
    }),
    aggregateRating: getAggregateRatingFromCoreConsumerReviews(consumerReviews),
  };
}

export function getVehicleMeasurementsSchemas(measurementsData) {
  const { width, height, weight } = measurementsData;

  return {
    width: {
      '@context': SCHEMA_URL,
      '@type': 'QuantitativeValue',
      unitText: 'in',
      value: width,
    },
    height: {
      '@context': SCHEMA_URL,
      '@type': 'QuantitativeValue',
      unitText: 'in',
      value: height,
    },
    weight: {
      '@context': SCHEMA_URL,
      '@type': 'QuantitativeValue',
      unitText: 'lbs',
      value: weight,
    },
  };
}

/**
 * Testing tool: https://search.google.com/structured-data/testing-tool
 * Schema: http://schema.org/Car
 *
 * @return {Object} seo vehicle description
 */
export function getExtendedCarSchema({
  makeModelSubmodelYear,
  style,
  editorialReview,
  consumerReviews,
  seoObject,
  priceRange = [0],
  url,
  thumbUrl,
  imageUrl,
  isCorePage = false,
}) {
  const features = get(style, 'features');

  if (!features) {
    return getCorePageCarSchema({
      makeModelSubmodelYear,
      editorialReview,
      consumerReviews,
      seoObject,
      priceRange,
      url,
      thumbUrl,
      imageUrl,
      isCorePage,
    });
  }

  const { generalData, mpg, engineData, measurementsData } = getSeoDataFromFeatures(features);
  const submodel = get(makeModelSubmodelYear, 'submodels.slug', '');
  const bodyType = submodel === 'suv' ? 'SUV' : capitalize(submodel);

  return {
    ...getCorePageCarSchema({
      makeModelSubmodelYear,
      editorialReview,
      consumerReviews,
      seoObject,
      priceRange,
      url,
      thumbUrl,
      imageUrl,
      isCorePage,
    }),
    bodyType,
    vehicleConfiguration: get(style, 'name', ''),
    seatingCapacity: get(style, 'numberOfSeats') || get(style, 'totalSeating'),
    ...generalData,
    ...getVehicleEngineSchema(engineData),
    ...getFuelEfficiencyCarSchema(mpg),
    ...getVehicleMeasurementsSchemas(measurementsData),
  };
}
