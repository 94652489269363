/**
 * @deprecated This entire file is deprecated, please use "/client/utils/image-helper.js" instead.
 * It SHOULD have all the ones that is not deprecated ported over to "image-helper.js"
 */
import { ClientConfig } from 'client/configuration';

const DEFAULT_DAM_PHOTO_SIZE = 1600;

/**
 * @deprecated Please use "/client/utils/image-helper.js" -> getStaticImageUrl .
 */
export function unversionedAssetUrl(src) {
  return `${ClientConfig.get('unversionedUrl', '')}${src}`;
}

/**
 * @deprecated Please use "/client/utils/image-helper.js" -> getDamPhotoUrl.
 */
export function getDamPhoto(src, options = {}) {
  const mediaUrl = `https:${ClientConfig.get('mediaCdnUrl')}`;
  const imagePath = /_([\d]+).(jpg)$/.test(src) ? src : `${src}_${options.size || DEFAULT_DAM_PHOTO_SIZE}.jpg`;
  return mediaUrl + imagePath.replace('dam:///photo', '').replace(/^(\/)*/, '/');
}

/**
 * @deprecated Please use "/client/utils/image-helper.js" -> getStaticImageUrl.
 */
export function getStaticImage(src) {
  return `https:${ClientConfig.get('unversionedUrl')}${src.replace(/^(\/)*/, '/')}`;
}
